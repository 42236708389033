export interface WebSocketNotification {
  action: string;
  channel: string;
  data: LiveValueNotification[] | StateUpdateNotification[] | any;
  matchingFilters: LiveValueFilter[] | any[];
}

export interface LiveValueFilter {
  dataPointId: number;
  propertyId: number;
}

export interface LiveValue {
  dtype: string;
  id: number;
  timestamp: number;
  value: number;
}

export interface LiveValueNotification {
  dataPointId: number;
  value: LiveValue;
}

export interface ParsedLiveValueNotification {
  action: string;
  channel: string;
  data: LiveValue;
  filter: LiveValueFilter;
}

export interface TicketValue {
  changeType: string;
  ticketId: number;
}

export interface TicketValueFilter {
  id: number;
  type: string;
}

export interface ParsedTicketNotification {
  action: string;
  channel: string;
  data: TicketValue;
  filter: TicketValueFilter;
}

export interface StateUpdateNotification {
  alarmState: number;
  alarmSuppression: number;
  allowedDevices: number[] | null;
  deviceId: number | null;
  id: number;
  manualMode: number;
  outOfServiceState: number;
  type: string;
  widget: StateUpdateWidgetNotification | null;
}

export interface StateUpdateWidgetNotification {
  dashboardId: number;
  groupId: number;
  name: string;
  pageId: number;
  widgetId: number;
  widgetType: string;
}

export interface StateUpdateFilter {
  id: number;
  type: string;
}

export interface ParseStateUpdateNotification {
  action: string;
  channel: string;
  data: StateUpdateNotification[];
  filter: StateUpdateFilter;
}

export enum WS_CHANNELS {
  DEVICE = 'DEVICE',
  TICKET = 'TICKET',
  NEW_DEVICE = 'NEW_DEVICE',
  BACNET_EVENTS = 'BACNET_EVENTS',
  PROCESS_FINISHED = 'PROCESS_FINISHED',
  LIVE_VALUE = 'LIVE_VALUE',
  STATE_UPDATE = 'STATE_UPDATE',
  RECORD_TASK = 'RECORD_TASK',
  TRENDLOG_VALUE = 'TRENDLOG_VALUE'
}

export enum WS_FILTER_TYPE {
  CLIENT = 'CLIENT',
  DEVICES_BY_CLIENT_ID = 'DEVICES_BY_CLIENT_ID',
  DATA_POINT = 'DATAPOINT',
  TICKET_LIST = 'TICKET_LIST',
  GROUP = 'GROUP'
}
