import {Injectable} from '@angular/core';
import {translate} from '@jsverse/transloco';
import {ActiveToast, ToastrService} from 'ngx-toastr';
import {RxWebSocket} from '../../../../../alcedo/src/app/shared/services/websocket/rx-web-socket';

@Injectable({providedIn: 'root'})
export class WebSocketService {
  static WS_CHANNELS = {
    DEVICE: 'DEVICE',
    LIVE_VALUE: 'LIVE_VALUE'
  };
  socket: any;
  toast: ActiveToast<any>;
  message = 'No connection to the server.';

  private valueFinders = {};

  constructor(private toastrService: ToastrService) {
    this.valueFinders[WebSocketService.WS_CHANNELS.DEVICE] = this.findDevice;
    this.valueFinders[WebSocketService.WS_CHANNELS.LIVE_VALUE] = this.findLiveValue;
  }

  getSocket() {
    if (this.socket) {
      return this.socket;
    } else {
      return this.createSocket();
    }
  }

  createSocket() {
    const options = {
      url: window.location.host + '/api/v1/notification/iot',
      handleResponseMessage: (message, observer) => {
        if (message.matchingFilters && message.matchingFilters.length) {
          const valueFinder = this.valueFinders[message.channel];
          const filterType = message.matchingFilters[0].type;
          if (valueFinder && (filterType === undefined || filterType === 'DATAPOINT')) {
            oneToOneBroadcast(message, observer, valueFinder);
          } else {
            oneToOneBroadcast(message, observer, x => x);
          }
        } else {
          observer.next(message);
        }
      },
      transformRequest: message => {
        if (message.filters && message.filters.length) {
          let i = message.filters.length - 1;
          for (; i > -1; i--) {
            message.filters[i].type = message.channel;
            message.filters[i].value = message.filters[i].filter;
            delete message.filters[i].filter;
          }
        }

        return message;
      }
    };

    function oneToOneBroadcast(message, observer, valueFinder) {
      let i = 0;
      const matchingFilters = message.matchingFilters;
      for (; i < matchingFilters.length; i++) {
        const matchinFilter = matchingFilters[i];
        observer.next({
          action: message.action,
          channel: message.channel,
          filter: matchinFilter,
          data: valueFinder(message.data, matchinFilter)
        });
      }
    }

    this.socket = new RxWebSocket(options);

    let numberOfRetries = 0;
    this.socket.connectionStatus.subscribe(status => {
      if (status === this.socket.connectionStatusOptions.connectionRetry && !this.toast && !document.hidden) {
        numberOfRetries++;
        if (numberOfRetries > 2) {
          this.toast = this.toastrService.error(translate('MAINTENANCE.NO_CONNECTION_TITLE'), translate('GENERAL.TITLE.ERROR'));
        }
      } else if (status && status !== this.socket.connectionStatusOptions.closed && status !== this.socket.connectionStatusOptions.connectionRetry) {
        if (this.toast) {
          this.toastrService.remove(this.toast.toastId);
          this.toast = null;
        }
        numberOfRetries = 0;
      }
    });
    return this.socket;
  }

  private findDevice(data, matchingFilter) {
    if (data.constructor === Array) {
      return data.find(d => d.id === matchingFilter.id);
    }
  }

  private findLiveValue(data, matchingFilter) {
    if (data.constructor === Array) {
      return data.find(d => d.dataPointId === matchingFilter.dataPointId && d.value.id === matchingFilter.propertyId).value;
    }
  }
}
