export enum AlcedoTypes {
  ACCELERATION = 1,
  ANGLE = 2,
  ANGULAR_MOMENTUM = 3,
  AREA = 4,
  BOOLEAN = 5,
  CAPACITANCE = 6,
  COMPRESSIBILITY = 7,
  CONDUCTANCE = 8,
  CONDUCTIVITY = 9,
  COOLING_RATE = 10,
  CURRENCY = 11,
  CURRENT = 12,
  CURRENT_DENSITY = 13,
  DATA_TRANSFER_SPEED = 14,
  DATA_VOLUME = 15,
  DENSITY = 16,
  DISTANCE = 17,
  DOUBLE = 18,
  ELECTRIC_FIELD_STRENGTH = 19,
  ENERGY = 20,
  ENTROPY = 21,
  FLOAT = 22,
  FORCE = 23,
  FREQUENCY = 24,
  ILLUMINANCE = 25,
  INDUCTANCE = 26,
  INSOLATION = 27,
  INTEGER = 28,
  IRRADIANCE = 29,
  LONG = 30,
  LUMINANCE = 31,
  LUMINOUS_FLUX = 32,
  LUMINOUS_INTENSITY = 33,
  MAGNETIC_FIELD_STRENGTH = 34,
  MAGNETIC_FLUX = 35,
  MAGNETIC_FLUX_DENSITY = 36,
  MAGNETIC_MOMENT = 37,
  MASS = 38,
  MASS_FLOW = 39,
  MOMENTUM = 40,
  NONE = 41,
  NULL = 42,
  RATIO = 43,
  POWER = 44,
  POWER_LEVEL = 45,
  PRESSURE = 46,
  RELATIVE_HUMIDITY = 47,
  RESISTANCE = 48,
  RESISTIVITY = 49,
  SPECIFIC_ENERGY = 50,
  SPECIFIC_HEAT = 51,
  STRING = 52,
  SURFACE_TENSION = 53,
  TEMPERATURE = 54,
  TEMPERATURE_DEPARTURE = 55,
  TEMPERATURE_DIFFERENCE = 56,
  THERMAL_CONDUCTIVITY = 57,
  THERMAL_TRANSMITTANCE = 58,
  THOMSON_COEFFICIENT = 59,
  TIME = 60,
  TIMESPAN = 61,
  UNSIGNED_INTEGER = 62,
  UNSIGNED_LONG = 63,
  VELOCITY = 64,
  VOLTAGE = 65,
  VOLUME = 66,
  VOLUME_CURRENT = 67,
  ABSOLUTE_HUMIDITY = 68,
  ALARM_ANALYSIS = 69,
  DECIBEL = 70,
  VOLTAGE_LEVEL = 71,
  RADIATION_ACTIVITY = 72,
  RADIATION_ABSORBED_DOSE = 73,
  RADIATION_EQUIVALENT_DOSE = 74,
  RADIATION_EQUIVALENT_DOSE_RATE = 75,
  A_WEIGHTING = 76,
  TURBIDITY = 77,
  ACIDITY = 78,
  AREA_DENSITY = 79,
  HEATING_RATE = 80,
  ELECTRIC_CHARGE = 81,
  AUXILIARY_ENERGY_1 = 82,
  AUXILIARY_ENERGY_2 = 83,
  VISCOSITY = 84,
  ENERGY_DENSITY = 86,
  EMISSION_COEFFICIENT = 87,
  BYTE = 88
}
