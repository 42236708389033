import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {translate} from '@jsverse/transloco';
import {polygonContains} from 'd3-polygon';
import {AIR_QUALITY_TEXT} from './air-quality-text.constant';
import {AIR_QUALITY_STATUS, AirQuality, COMFORT_ICON, ComfortArea, HEALTH_SCORE} from './air-quality.interface';
import {COMFORT_AREA} from './comfort-area.constant';
import {DataPoint} from './data-point.interface';
import {Device} from './device.interface';
import {DeviceService} from './device.service';
import {THRESHOLD} from './threshold.constant';

@Injectable({providedIn: 'root'})
export class AirQualityService {
  comfortArea = COMFORT_AREA;
  airQualityText = AIR_QUALITY_TEXT;

  constructor(private router: Router) {}

  static getDataPointHealthScore(dataPoint: DataPoint): HEALTH_SCORE {
    if (dataPoint && dataPoint.current && THRESHOLD[dataPoint.iotType]) {
      const config = THRESHOLD[dataPoint.iotType];

      if (dataPoint.current.value > config.DangerUpperLimit || dataPoint.current.value < config.DangerLowerLimit) {
        return HEALTH_SCORE.CRITICAL;
      } else if (dataPoint.current.value > config.ComfortUpperLimit || dataPoint.current.value < config.ComfortLowerLimit) {
        return HEALTH_SCORE.UNHEALTHY;
      } else {
        return HEALTH_SCORE.HEALTHY;
      }
    } else {
      return HEALTH_SCORE.UNKNOWN;
    }
  }

  static getHealthScoreIcon(score: number): string {
    switch (score) {
      case HEALTH_SCORE.CRITICAL:
        return COMFORT_ICON.UNCOMFORTABLE;
      case HEALTH_SCORE.UNHEALTHY:
        return COMFORT_ICON.STILL_COMFORTABLE;
      case HEALTH_SCORE.HEALTHY:
        return COMFORT_ICON.COMFORTABLE;
      default:
        return '';
    }
  }

  getComfortLevel(temperature: number, humidity: number): ComfortArea {
    const point: [number, number] = [temperature, humidity];
    if (polygonContains(this.comfortArea.comfortable.area, point)) {
      this.comfortArea.comfortable.text = translate('COMFORT_COMFORTABLE');
      return this.comfortArea.comfortable;
    } else if (polygonContains(this.comfortArea.stillComfortable.area, point)) {
      this.comfortArea.stillComfortable.text = translate('COMFORT_STILL_COMFORTABLE');
      return this.comfortArea.stillComfortable;
    } else if (polygonContains(this.comfortArea.uncomfortablyCold.area, point)) {
      this.comfortArea.uncomfortablyCold.text = translate('COMFORT_UNCOMFORTABLY_COLD');
      return this.comfortArea.uncomfortablyCold;
    } else if (polygonContains(this.comfortArea.uncomfortablyHot.area, point)) {
      this.comfortArea.uncomfortablyHot.text = translate('COMFORT_UNCOMFORTABLY_HOT');
      return this.comfortArea.uncomfortablyHot;
    } else if (polygonContains(this.comfortArea.uncomfortablyDry.area, point)) {
      this.comfortArea.uncomfortablyDry.text = translate('COMFORT_UNCOMFORTABLY_DRY');
      return this.comfortArea.uncomfortablyDry;
    } else if (polygonContains(this.comfortArea.uncomfortablyDamp.area, point)) {
      this.comfortArea.uncomfortablyDamp.text = translate('COMFORT_UNCOMFORTABLY_DAMP');
      return this.comfortArea.uncomfortablyDamp;
    } else {
      this.comfortArea.uncomfortable.text = translate('COMFORT_UNCOMFORTABLE');
      return this.comfortArea.uncomfortable;
    }
  }

  determineAirQuality(device: Device): void {
    const humidityDataPoint = DeviceService.getDataPointByIoTType(device, DeviceService.HUMIDITY_TYPES);
    const temperatureDataPoint = DeviceService.getDataPointByIoTType(device, DeviceService.TEMPERATURE_TYPES);

    const airQuality: AirQuality = {
      co2HealthScore: HEALTH_SCORE.UNKNOWN,
      vocHealthScore: HEALTH_SCORE.UNKNOWN,
      airQualityScore: HEALTH_SCORE.UNKNOWN,
      airQualityText: '',
      comfort: undefined,
      airQualityIcon: '',
      airQualityStatus: AIR_QUALITY_STATUS.COMFORT
    };

    if (temperatureDataPoint && temperatureDataPoint.current && humidityDataPoint && humidityDataPoint.current) {
      airQuality.comfort = this.getComfortLevel(temperatureDataPoint.current.value, humidityDataPoint.current.value);

      airQuality.airQualityScore = airQuality.comfort ? airQuality.comfort.healthScore : HEALTH_SCORE.UNKNOWN;
      airQuality.airQualityText = airQuality.comfort ? airQuality.comfort.text : '';
      airQuality.airQualityStatus = AIR_QUALITY_STATUS.COMFORT;

      this.determineVOC(device, airQuality);
      this.determineCO2(device, airQuality);
    }
    airQuality.airQualityIcon = AirQualityService.getHealthScoreIcon(airQuality.airQualityScore);
    device.airQuality = airQuality;
  }

  navigateToDetails(device: Device): void {
    // Navigate to the view that is currently the least healthy.
    switch (device.airQuality.airQualityStatus) {
      case AIR_QUALITY_STATUS.CO2:
        const co2DataPoint = DeviceService.getDataPointByIoTType(device, DeviceService.CO2_TYPES);
        if (co2DataPoint) {
          this.router.navigate(['device', device.activationCode, 'dp', co2DataPoint.id]);
        } else {
          this.navigateToComfortChart(device);
        }
        break;
      case AIR_QUALITY_STATUS.VOC:
        const vocDataPoint = DeviceService.getDataPointByIoTType(device, DeviceService.VOC_TYPES);
        if (vocDataPoint) {
          this.router.navigate(['device', device.activationCode, 'dp', vocDataPoint.id]);
        } else {
          this.navigateToComfortChart(device);
        }
        break;
      case AIR_QUALITY_STATUS.COMFORT:
      default:
        this.navigateToComfortChart(device);
    }
  }

  navigateToComfortChart(device: Device): void {
    this.router.navigate(['device', device.activationCode, 'comfort']);
  }

  private determineVOC(device: Device, airQuality: AirQuality): void {
    const vocDataPoint = DeviceService.getDataPointByIoTType(device, DeviceService.VOC_TYPES);
    let vocHealthScore = HEALTH_SCORE.UNKNOWN;
    // If VOC levels are elevated, override comfort and show VOC warning instead.
    if (vocDataPoint && vocDataPoint.current) {
      this.airQualityText.VOC[HEALTH_SCORE.HEALTHY] = translate('VOC_LEVEL_NORMAL');
      this.airQualityText.VOC[HEALTH_SCORE.UNHEALTHY] = translate('VOC_LEVEL_ELEVATED');
      this.airQualityText.VOC[HEALTH_SCORE.CRITICAL] = translate('VOC_LEVEL_HIGH');
      vocHealthScore = AirQualityService.getDataPointHealthScore(vocDataPoint);
      if (vocHealthScore >= airQuality.airQualityScore && vocHealthScore >= HEALTH_SCORE.UNHEALTHY) {
        airQuality.airQualityScore = vocHealthScore;
        airQuality.airQualityText = this.airQualityText.VOC[vocHealthScore];
        airQuality.airQualityStatus = AIR_QUALITY_STATUS.VOC;
      }
    }
  }

  private determineCO2(device: Device, airQuality: AirQuality): void {
    const co2DataPoint = DeviceService.getDataPointByIoTType(device, DeviceService.CO2_TYPES);
    // If CO2 levels are elevated, override comfort/VOC and show CO2 warning instead.
    let co2HealthScore = HEALTH_SCORE.UNKNOWN;
    if (co2DataPoint && co2DataPoint.current) {
      this.airQualityText.CO2[HEALTH_SCORE.HEALTHY] = translate('CO2_LEVEL_NORMAL');
      this.airQualityText.CO2[HEALTH_SCORE.UNHEALTHY] = translate('CO2_LEVEL_ELEVATED');
      this.airQualityText.CO2[HEALTH_SCORE.CRITICAL] = translate('CO2_LEVEL_HIGH');
      co2HealthScore = AirQualityService.getDataPointHealthScore(co2DataPoint);
      if (co2HealthScore >= airQuality.airQualityScore && co2HealthScore >= HEALTH_SCORE.UNHEALTHY) {
        airQuality.airQualityScore = co2HealthScore;
        airQuality.airQualityText = this.airQualityText.CO2[co2HealthScore];
        airQuality.airQualityStatus = AIR_QUALITY_STATUS.CO2;
      }
    }
  }
}
