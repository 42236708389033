import {translate} from '@jsverse/transloco';
import {marker} from '@jsverse/transloco-keys-manager/marker';
import {ConnectionState} from 'api/entities';

export const ConnectionStateLabels: Record<ConnectionState, string> = {
  get [ConnectionState.MAINTENANCE]() {
    return translate(marker('CONNECTION_STATE.MAINTENANCE'));
  },
  get [ConnectionState.OFFLINE]() {
    return translate(marker('CONNECTION_STATE.OFFLINE'));
  },
  get [ConnectionState.ONLINE]() {
    return translate(marker('CONNECTION_STATE.ONLINE'));
  },
  get [ConnectionState.UPDATING]() {
    return translate(marker('CONNECTION_STATE.UPDATING'));
  }
};
