import {Injectable} from '@angular/core';

import * as Apollo from 'apollo-angular';
import {gql} from 'apollo-angular';
import * as Types from 'types';

export type LiveValueSubscriptionSubscriptionVariables = Types.Exact<{
  filter: Array<Types.InputMaybe<Types.Scalars['Int']['input']>> | Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type LiveValueSubscriptionSubscription = { __typename?: 'Subscription', liveValueSubscription?: Array<{ __typename?: 'LiveValueTO', dataPointId: number, value: any } | null> | null };

export const LiveValueSubscriptionDocument = gql`
    subscription liveValueSubscription($filter: [Int]!) {
  liveValueSubscription(filter: $filter) {
    dataPointId
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LiveValueSubscriptionGQL extends Apollo.Subscription<LiveValueSubscriptionSubscription, LiveValueSubscriptionSubscriptionVariables> {
    document = LiveValueSubscriptionDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
