import {Injectable} from '@angular/core';
import {ConnectionState} from 'api/entities';
import {BehaviorSubject} from 'rxjs';
import {orderByLocale} from '../../../../../alcedo/src/app/shared/services/order-by-locale.service';
import {ConnectionStateLabels} from '../connection-state-labels.constant';
import {DeviceTypeLabels} from '../device-type-labels.constant';
import {Device} from './device.interface';

interface FilterOption {
  label: string;
  value: string;
  isActive: boolean;
}

const STATUSES_ORDER: string[] = [ConnectionState.ONLINE, ConnectionState.OFFLINE, ConnectionState.UPDATING, ConnectionState.MAINTENANCE];

@Injectable({providedIn: 'root'})
export class DevicesFilterService {
  searchDeviceName = '';
  isFilterOpen = false;
  areAllDeviceTypeFiltersActive = true;
  areFiltersPristine = true;
  filtersChange = new BehaviorSubject<void>(null);
  statusFilters: FilterOption[] = [];
  deviceTypeFilters: FilterOption[] = [];
  private activeStatuses: string[] = [];
  private activeDeviceTypes: string[] = [];

  updateAvailableFilters(devices: Device[]): void {
    const statusesSet = new Set(devices.map(device => device.connectionState));
    this.statusFilters = this.getFilterOptions(statusesSet, this.statusFilters, ConnectionStateLabels);
    this.statusFilters.sort((a, b) => STATUSES_ORDER.indexOf(a.value) - STATUSES_ORDER.indexOf(b.value));

    const deviceTypesSet = new Set(devices.map(device => device.deviceType));
    this.deviceTypeFilters = this.getFilterOptions(deviceTypesSet, this.deviceTypeFilters, DeviceTypeLabels);
    this.deviceTypeFilters = orderByLocale(this.deviceTypeFilters, 'label');

    this.updateFilters();
  }

  cancelFilters(cancelSearchName: boolean = false): void {
    cancelSearchName && (this.searchDeviceName = '');
    this.isFilterOpen = false;
    this.statusFilters.forEach(option => (option.isActive = true));
    this.deviceTypeFilters.forEach(option => (option.isActive = true));
    this.updateFilters();
  }

  changeAllDeviceTypeFiltersActive(): void {
    const newIsActive = !this.areAllDeviceTypeFiltersActive;
    this.deviceTypeFilters.forEach(option => (option.isActive = newIsActive));
    this.updateFilters();
  }

  updateFilters(searchName: string = this.searchDeviceName): void {
    this.searchDeviceName = searchName;
    this.activeStatuses = this.statusFilters.filter(option => option.isActive).map(option => option.value);
    this.activeDeviceTypes = this.deviceTypeFilters.filter(option => option.isActive).map(option => option.value);
    this.checkFilterFlags();
    this.filtersChange.next();
  }

  isDeviceValid(device: Device): boolean {
    return (
      device.name.toLowerCase().includes(this.searchDeviceName.toLowerCase()) &&
      this.activeStatuses.includes(device.connectionState) &&
      this.activeDeviceTypes.includes(device.deviceType)
    );
  }

  private getFilterOptions(sourceValuesSet: Set<string>, targetOptions: FilterOption[], labelRecords: Record<string, string>): FilterOption[] {
    const sourceValues = Array.from(sourceValuesSet);
    if (targetOptions.length === sourceValues.length) {
      return targetOptions;
    }

    return sourceValues.map(sourceValue => {
      const existingOption = targetOptions.find(targetOption => targetOption.value === sourceValue);
      if (existingOption) {
        return existingOption;
      }
      return {label: labelRecords[sourceValue], value: sourceValue, isActive: true};
    });
  }

  private checkFilterFlags(): void {
    this.areAllDeviceTypeFiltersActive = this.activeDeviceTypes.length === this.deviceTypeFilters.length;
    this.areFiltersPristine = this.areAllDeviceTypeFiltersActive && this.activeStatuses.length === this.statusFilters.length;
  }
}
